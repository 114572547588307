import { useState } from "react";

// ui
import { TextField } from "@mui/material";

// img
import Mega from "../../img/common/USA_Mega_lottery.png";
// import Power from "../../img/common/USA_Power_lottery.png";

const SyndicateTest = () => {
  const [flipped, setFlipped] = useState(false);

  return (
    <div className="sub syndicate-test">
      <div
        className="syndicate-card-wrap"
        onClick={() => {
          setFlipped(!flipped);
        }}
      >
        <div className={`card ${flipped ? "flipped" : ""}`}>
          <div className="front">
            <p className="tit">USA Power 50 Games</p>
            <div className="game-info-wrap">
              <img src={Mega} alt="" />
              <div className="info">
                {/* <p className="total">50 Games</p> */}
                <p className="prize">$ 592.1 M</p>
                <p className="date">Close in : 3 days, 3 hrs</p>
                <div className="tag-wrap">
                  <p className="tag multiplier">Multiplier</p>
                  <p className="tag guaranteed">Guaranteed</p>
                </div>
              </div>
            </div>
            <div className="remain-quantity-wrap">
              <div className="quantity-control">
                <div className="btn minus">-</div>
                <TextField id="" defaultValue={1} sx={{ width: "60px" }} />
                <div className="btn plus">+</div>
              </div>
              <p className="remain-total">
                <span>Remaining : 14</span> <span>Total Shares : 50</span>
              </p>
            </div>

            <p className="price">
              Total : <span>$ 15.00</span>
            </p>

            <div className="btn style01 md spc">Join the Game</div>
          </div>
          <div className="back">
            <div className="show-number">
              <div className="number-wrap">
                <div className="number">
                  <p className="">#1</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#2</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#3</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#4</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
                <div className="number">
                  <p className="">#5</p>
                  <div className="ball-wrap">
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball">1</div>
                    <div className="ball mega">1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyndicateTest;
