// tools
import { Link } from "react-router-dom";

// ui
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const newsItems = [
  {
    title: "Eye-watering US jackpot Aussies have a chance at winning",
    date: "August 2, 2024 - 10:59AM",
    thumb:
      "https://content.api.news/v3/images/bin/d3d27e25a6d897fe62a678c6db8eadf7?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/eyewatering-us-jackpot-aussies-have-a-chance-at-winning/news-story/f84d5d858fc687548e1810878dd3dfce",
  },
  {
    title: "Big secret half of Australians would keep",
    date: "July 23, 2024 - 11:52AM",
    thumb:
      "https://content.api.news/v3/images/bin/a11cee79b45e2193792fd56ec0856f28?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/big-secret-half-of-australians-would-keep/news-story/79fe49912c09bbb84a729c88ab82e806",
  },
  {
    title:
      "Paying off the mortgage or buying a house are the first things Aussies would do if they won the lottery",
    date: "June 25, 2024 - 5:00AM",
    thumb:
      "https://content.api.news/v3/images/bin/09fa1df268f8ae88c8c52bc445aa7a56?width=1024",
    link: "https://www.news.com.au/national/paying-off-the-mortgage-or-buying-a-house-are-the-first-things-aussies-would-do-if-they-won-the-lottery/news-story/88db5ac779300774eb928f4400accee8",
  },
  {
    title: "Aussies given chance at $738 million USA Mega Lotto draw",
    date: "May 28, 2024 - 1:38PM",
    thumb:
      "https://content.api.news/v3/images/bin/be9833b7ca180e37a36d1754fe92e6af?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/aussies-given-chance-at-684-million-usa-mega-lotto-draw/news-story/afac03de1d52292205ac42c8a75dd355",
  },
  {
    title: "Eye-watering US jackpot Aussies have a chance at winning",
    date: "August 2, 2024 - 10:59AM",
    thumb:
      "https://content.api.news/v3/images/bin/d3d27e25a6d897fe62a678c6db8eadf7?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/eyewatering-us-jackpot-aussies-have-a-chance-at-winning/news-story/f84d5d858fc687548e1810878dd3dfce",
  },
  {
    title: "Big secret half of Australians would keep",
    date: "July 23, 2024 - 11:52AM",
    thumb:
      "https://content.api.news/v3/images/bin/a11cee79b45e2193792fd56ec0856f28?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/big-secret-half-of-australians-would-keep/news-story/79fe49912c09bbb84a729c88ab82e806",
  },
  {
    title:
      "Paying off the mortgage or buying a house are the first things Aussies would do if they won the lottery",
    date: "June 25, 2024 - 5:00AM",
    thumb:
      "https://content.api.news/v3/images/bin/09fa1df268f8ae88c8c52bc445aa7a56?width=1024",
    link: "https://www.news.com.au/national/paying-off-the-mortgage-or-buying-a-house-are-the-first-things-aussies-would-do-if-they-won-the-lottery/news-story/88db5ac779300774eb928f4400accee8",
  },
  {
    title: "Aussies given chance at $738 million USA Mega Lotto draw",
    date: "May 28, 2024 - 1:38PM",
    thumb:
      "https://content.api.news/v3/images/bin/be9833b7ca180e37a36d1754fe92e6af?width=1024",
    link: "https://www.news.com.au/finance/money/wealth/aussies-given-chance-at-684-million-usa-mega-lotto-draw/news-story/afac03de1d52292205ac42c8a75dd355",
  },
];

const News = () => {
  // Slick slide
  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr next" onClick={onClick}>
        <span className="material-symbols-rounded">arrow_forward_ios</span>
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr prev" onClick={onClick}>
        <span
          className="material-symbols-rounded"
          style={{ marginRight: "-6px" }}
        >
          arrow_back_ios
        </span>
      </div>
    );
  };
  const SliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    draggable: true,
    swipeToSlide: true,
    className: "news-slide",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section className="news-area">
      <Link className="tit-wrap btn" to="/news">
        <h3 className="tit">CLUSTER NEWS</h3>
        <span className="material-symbols-rounded">chevron_right</span>
      </Link>

      <Slider {...SliderSettings}>
        {newsItems.map((it, idx) => (
          <a
            className="item"
            key={idx}
            href={it.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img-wrap">
              <img src={it.thumb} alt="" />
            </div>
            <div className="txt-wrap">
              <h4 className="tit">{it.title}</h4>
              <p className="date">{it.date}</p>
            </div>
          </a>
        ))}
      </Slider>
    </section>
  );
};

export default News;
