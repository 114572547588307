import App from "../App";
import { createBrowserRouter } from "react-router-dom";

// pages
import Signup from "../pages/signUp";
import Mega from "../pages/mega";
import Power from "../pages/power";
import Result from "../pages/result";
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";
import AboutUs from "../pages/aboutUs";
import ErrorPage from "../pages/errorPage";
import Google from "../pages/google";
import Ticket from "../pages/ticket";
import Main from "../pages/main";
import Stat from "../pages/stat";
import Shorts from "../pages/shorts";
import News from "../pages/news";
import Magazine from "../pages/magazine";
import EmailUs from "../pages/emailUs";
import Mypage from "../pages/mypage";
import Faq from "../pages/faq";
import Syndicates from "../pages/syndicates";
import Cart from "../pages/cart";
import Design from "../pages/DESIGN/design";

import Result2 from "../pages/result2";

// comp
import MagaDetail from "../components/magazine/detail";
import Favorites from "../pages/TEST/favoritesTest";
import FavoritesTest from "../pages/TEST/favoritesTest";
import ResultTest from "../pages/DESIGN/resultTest";

import SyndicateNew from "../pages/DESIGN/syndicateNew";
import SyndicateNew2 from "../pages/DESIGN/syndicateNew2";
import SyndicateNew3 from "../pages/DESIGN/syndicateNew3";
import SyndicateNew4 from "../pages/DESIGN/syndicateNew4";

import SyndicateNewNew from "../pages/syndicateCreate";
import SyndicateNewNew2 from "../pages/DESIGN/syndicateNewNew2";
import SyndicateCreate from "../pages/syndicateCreate";
import SyndicateTest from "../pages/DESIGN/syndicateTest";

// DESIGN
// import New from "../pages/DESIGN/NO_USE/gameDesign2";
// import New2 from "../pages/DESIGN/NO_USE/gameDesign";
// import New3 from "../components/syndicates/New3";
// import Cart from "../pages/DESIGN/NO_USE/cartDesign";
// import Cart2 from "../pages/DESIGN/cart2";

// routes setting
const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      // --- 새 디자인 작업 영역 ---
      { path: "/design", element: <Design /> },
      { path: "/resulttest", element: <ResultTest /> },
      // { path: "/new2", element: <New2 /> },
      // { path: "/cart", element: <Cart /> },
      // { path: "/cart2", element: <Cart2 /> },
      // { path: "/new3", element: <New3 /> },

      // -----------------------

      // 기존 작업 영역
      { path: "/signup", element: <Signup /> },
      { path: "/welcome", element: <Signup /> },
      { path: "/about", element: <AboutUs /> },
      { path: "/mega", element: <Mega /> },
      { path: "/power", element: <Power /> },
      { path: "/syndicates", element: <Syndicates /> },
      { path: "/syndicates/create", element: <SyndicateCreate /> },

      { path: "/cart", element: <Cart /> },
      { path: "/result", element: <Result /> },
      { path: "/result2", element: <Result2 /> },
      { path: "/faq", element: <Faq /> },
      { path: "/stat", element: <Stat /> },
      { path: "/news", element: <News /> },
      { path: "/email", element: <EmailUs /> },
      { path: "/mypage", element: <Mypage /> },
      { path: "/privacy", element: <Privacy /> },
      { path: "/terms", element: <Terms /> },
      { path: "/auth/google", element: <Google /> },
      { path: "/ticket", element: <Ticket /> },
      { path: "/magazine", element: <Magazine /> },
      { path: "/magazine/detail", element: <MagaDetail /> },
      { path: "/magazine/shorts", element: <Shorts /> },

      // 테스트 작업 영역
      { path: "/test", element: <FavoritesTest /> },
      { path: "/syndicates/test", element: <SyndicateTest /> },
      // { path: "/syndicate/new", element: <SyndicateNew /> },
      // { path: "/syndicate/new2", element: <SyndicateNew2 /> },
      // { path: "/syndicate/new3", element: <SyndicateNew3 /> },
      // { path: "/syndicate/new4", element: <SyndicateNew4 /> },
      // { path: "/syndicate/newnew2", element: <SyndicateNewNew2 /> },
    ],
  },
]);

export default routes;
