// tools
import { useEffect, useState } from "react";
import User from "../tools/userInfo";

// comp
import SynItem from "../components/syndicates/synItem";
import NoData from "../components/common/noData";
import createAxiosInstance from "../api/axiosConfig";
import Swal from "sweetalert2";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";

// type
export interface Nums {
  idx: number;
  num: string;
}
export interface SyndicateInfo {
  idx: number;
  name: string;
  systemCode: string | null;
  auDrawTime: string;
  syndicateName: string;
  description: string;
  drawnum: number;
  games: number;
  jackpot: number;
  multiplier: string;
  price: string;
  productCode: string;
  ticketIssued: number;
  ticketSold: number;
  nums: Nums[];
}

const Syndicates = () => {
  const [upDown, setUpDown] = useState(false);
  const [upDown2, setUpDown2] = useState(false);

  const { api, token, setToken, setTotalInfo } = User();
  const [data, setData] = useState<SyndicateInfo[]>([]);

  const axiosInstance = createAxiosInstance(api, "");

  const getSynData = () => {
    axiosInstance
      .get(`/product/syndicate`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const linkToCreate = () => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
        allowOutsideClick: false,
      });
    } else {
      location.href = "/syndicates/create";
    }
  };

  useEffect(() => {
    getSynData();
  }, []);

  if (data.length === 0) {
    return <NoData />;
  }

  return (
    <div className="sub syndicate">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">Play with a group and get more chances to win</p>

        <div className="btn style01 md" onClick={() => linkToCreate()}>
          Create Your Syndicate
        </div>
      </div>
      <div className="filter-wrap">
        <div className="tit">
          <span className="material-symbols-rounded">tune</span>
          <p>Filter</p>
        </div>

        <div
          className={`updown filter ${upDown && "up"}`}
          onClick={() => {
            setUpDown(!upDown);
          }}
        >
          <p>Draw</p>
          <span className="material-symbols-rounded">arrow_drop_down</span>
        </div>

        <div
          className={`updown filter ${upDown2 && "up"}`}
          onClick={() => {
            setUpDown2(!upDown2);
          }}
        >
          <p>Prize</p>
          <span className="material-symbols-rounded">arrow_drop_down</span>
        </div>

        <FormControl className="filter">
          <InputLabel id="">System</InputLabel>
          <Select labelId="" id="System" value={1} label="System">
            <MenuItem value={1}>Guaranteed</MenuItem>
            <MenuItem value={2}>System6</MenuItem>
            <MenuItem value={3}>System7</MenuItem>
            <MenuItem value={3}>System8</MenuItem>
            <MenuItem value={3}>System9</MenuItem>
            <MenuItem value={3}>System10</MenuItem>
            <MenuItem value={3}>Pick 1 Less</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="syndicate-wrap">
        {data.map((it) => (
          <SynItem key={it.idx} data={it} getSynData={getSynData} />
        ))}
      </div>
    </div>
  );
};

export default Syndicates;
