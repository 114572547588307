// tools
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { reset } from "../../tools/zustandUpdate";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";
import axios from "axios";

// comp
import Login from "./login";

// img
import NewLogo from "../../img/common/LC_logo_ani_h.svg";

import { useLotto } from "../../App";
import createAxiosInstance from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";

const Header = ({ isScrolled }: any) => {
  const { api, token, info, setToken, setTotalInfo } = User();
  const { open, setOpen, noLoginCart, checkNoLoginCart } = useLotto();
  const axiosInstance = createAxiosInstance(api, token);
  const navigate = useNavigate();

  // refresh token
  useEffect(() => {
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const origin = payload.iss;

      if (origin.includes("/login") || origin.includes("/refresh")) {
        const expire = payload.exp;
        const diff = new Date().getTimezoneOffset() + 540;
        const time = new Date().getTime();
        const now = (time - (time % 1000)) / 1000;
        const converted = now + diff * 60;

        if (converted > expire) {
          axiosInstance.get(`/auth/me`).catch(({ response }) => {
            axios
              .get(`${api}/auth/refresh`, {
                headers: {
                  "ngrok-skip-browser-warning": "69420",
                  Authorization: "bearer " + response.data.data.refresh_token,
                },
              })
              .then(({ data }) => {
                setToken(data.data.token);
              })
              .catch((error) => console.log(error));
          });
        }
      }
    }

    checkNoLoginCart();
  }, []);

  // logOut
  const logoutAxios = () => {
    axiosInstance.post(`/logout`).then(({ data }) => {
      if (data.message === "success") {
        Swal.fire({
          title: "You have logged out successfully",
          icon: "success",
          allowOutsideClick: false,
          timer: 2000,
          didClose: () => {
            setToken("");
            reset(setTotalInfo);
            location.href = "/";
          },
        });
      }
    });
  };

  // scroll event
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const headerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!isTablet) return;

    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (headerRef.current) {
        if (currentScrollTop > lastScrollTop) {
          headerRef.current.classList.add("hide");
        } else {
          headerRef.current.classList.remove("hide");
        }
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTablet, lastScrollTop]);

  return (
    <header className={isScrolled ? "header scroll" : "header"} ref={headerRef}>
      <div className="inner">
        <div className="logo btn" onClick={() => (window.location.href = "/")}>
          <img src={NewLogo} alt="Lottery Cluster" />
        </div>

        <ul className="gnb">
          <li onClick={() => navigate("/power?Q")}>USA Power Lottery</li>
          <li onClick={() => navigate("/mega?Q")}>USA Mega Lottery</li>
          <li onClick={() => navigate("/syndicates")}>Syndicates</li>
          <li onClick={() => navigate("/result2?tab=power")}>Results</li>
          <li onClick={() => navigate("/stat?type=power&tab=lastdrawn")}>
            Statistics
          </li>
          <li onClick={() => navigate("/faq")}>FAQ</li>
        </ul>

        <div className="user-nav">
          {token === "" ? (
            <div className="btn style01 sm" onClick={() => setOpen(true)}>
              Login
            </div>
          ) : (
            <>
              <div className="btn mypage" onClick={() => navigate("/mypage")}>
                <span className="material-symbols-rounded">
                  account_balance_wallet
                </span>
                $ 180.02
              </div>
              {/* <div className="btn style01 sm" onClick={() => logoutAxios()}>
                <span className="material-symbols-rounded">logout</span>
                Logout
              </div> */}
            </>
          )}
        </div>

        <div className="btn cart" onClick={() => (location.href = "/cart")}>
          <span className="material-symbols-rounded">shopping_cart</span>

          {token.length === 0 ? (
            <span className="badge">
              {noLoginCart > 100 ? "99" : noLoginCart}
            </span>
          ) : (
            <span className="badge">{info.cart > 100 ? "99" : info.cart}</span>
          )}
        </div>
      </div>

      <Login open={open} setOpen={setOpen} />
    </header>
  );
};

export default Header;
