import { ReactElement, useEffect, useState } from "react";

// ui
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import createAxiosInstance from "../api/axiosConfig";
import User from "../tools/userInfo";
import Swal from "sweetalert2";
import { make1dollar } from "../tools/make1dollar";
import CreateCard from "../components/syndicates/createCard";
import { Ball, BallSet, Nums } from "../types/ball";
import { RandomNumber } from "../tools/random";
import ShowNums from "../components/cart/showNums";

// tpye
interface SynGame {
  type: string;
  system: string;
  qty: number;
  date: string;
  multi: false;
  share: number;
  price: number;
  title: string;
  explain: string;
}

interface SystemMenu {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
}

export interface GameCard {
  id: number;
  wBall: (number | string)[] | number[];
  sBall: number | string;
  method: string;
}

const SyndicateCreate = () => {
  // Tools
  const { api, token, info } = User();
  const axiosInstance = createAxiosInstance(api, token);

  // Setting's
  const [type, setType] = useState<string>("power");

  const [multi, setMulti] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [agree, setAgree] = useState<boolean>(false);

  // System List / select Sys / create 1 $ game / minimum price
  const [system, setSystem] = useState<SystemMenu[]>([]);
  const [selectSystem, setSelectSystem] = useState<string>("STD");
  const [setting1, setSetting1] = useState<boolean>(false);
  const [minDollar, setMinDollar] = useState<boolean>(false);

  // State's
  // Game count / share count
  const [qty, setQty] = useState<number>(1);
  const [share, setShare] = useState<number>(1);
  const [gameText, setGameText] = useState({
    title: "",
    explain: "",
  });

  // Game card
  const [card, setCard] = useState<GameCard[]>([]);

  // User account
  const hasAccount = Number(info.cash) + Number(info.prize);

  /** Select System - price, number set */
  const priceAcc = (system: string) => {
    const price = multi ? 10.45 : 7;
    switch (system) {
      case "STD":
        return price;
      case "GUA":
        return price * 26;
      case "PIC":
        return price * 65;
      case "ST6":
        return price * 6;
      case "ST7":
        return price * 21;
      case "ST8":
        return price * 56;
      case "ST9":
        return price * 126;
      case "STX":
        return price * 252;
    }
  };

  /** 총 가격 */
  const price = Number((Number(priceAcc(selectSystem)) * qty).toFixed(2));

  /** STEP 유효성 검사 */
  const step1 = hasAccount < price ? false : true;
  const step2 =
    !minDollar && gameText.title.length > 1 && gameText.explain.length > 1
      ? true
      : false;
  const step3 = card.every((it) => !it.wBall.includes(0) && it.sBall !== 0);

  /** STEP disabled Check */
  const isDisabledStep = () => {
    if (step === 0) {
      if (step1 === true) {
        return "";
      } else {
        return "disabled";
      }
    } else if (step === 1) {
      if (step2 === true) {
        return "";
      } else {
        return "disabled";
      }
    } else if (step === 2) {
      if (step3 === true) {
        return "";
      } else {
        return "disabled";
      }
    }
  };

  /** STEP MOVE Handler */
  const nextStepHandler = () => {
    if (step === 0) {
      if (step1 === true) {
        setStep(1);
      }
      // setStep(1);
    } else if (step === 1) {
      if (step2 === true) {
        setStep(2);
      }
    } else if (step === 2) {
      if (step3 === true) {
        setStep(3);
      }
    }
  };

  const checkAccount = () => {
    if (hasAccount >= price) {
      return;
    } else {
      return (
        <span className="notice">
          $ {(hasAccount - Number(price)).toFixed(2)} dollars short.
        </span>
      );
    }
  };

  const SystemFinder = (code: string) => {
    switch (code) {
      case "STD":
        return "Standard";
      case "ST6":
        return "System6";
      case "ST7":
        return "System7";
      case "ST8":
        return "System8";
      case "ST9":
        return "System9";
      case "STX":
        return "System10";
      case "GUA":
        return "Guaranteed";
      case "PIC":
        return "Pick 1 Less";
    }
  };

  /** Select handler */
  const handleChange = (e: SelectChangeEvent<string>, kind: string) => {
    if (kind === "type") {
      setType(e.target.value);
    } else if (kind === "system") {
      setSelectSystem(String(e.target.value));
    }
  };

  /** Text handler */
  const handleTextChange = (name: string, value: string) => {
    setGameText((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /** Clear Card Nums */
  const handleClear = (target: number) => {
    const updatedCard = card.map((it) => {
      if (it.id === target) {
        const clearWball = it.wBall.map((ball) => (ball === "G" ? "G" : 0));
        const clearSball = it.sBall === "G" ? "G" : 0;
        return { ...it, wBall: clearWball, sBall: clearSball };
      }
      return it;
    });
    setCard(updatedCard);
  };

  /** handle Pick */
  const handlePick = (
    id: number,
    number: number | string,
    isSpecial: boolean
  ) => {
    setCard((prevCards) =>
      prevCards.map((card) => {
        if (card.id !== id) {
          return card;
        }

        if (isSpecial) {
          return {
            ...card,
            sBall:
              card.sBall === "G" ? "G" : card.sBall === number ? 0 : number,
          };
        } else {
          const updatedWballs = card.wBall.map((ball) =>
            ball === "G" ? "G" : Number(ball)
          );

          const existingIndex = updatedWballs.indexOf(Number(number));
          if (existingIndex !== -1) {
            updatedWballs[existingIndex] = 0;
          } else {
            const zeroIndex = updatedWballs.indexOf(0);
            if (zeroIndex !== -1) {
              updatedWballs[zeroIndex] = Number(number);
            }
          }

          const sortedBalls = updatedWballs
            .filter((ball) => ball !== "G")
            .sort((a, b) => Number(a) - Number(b));
          let finalBalls: (number | string)[] = [];
          let sortedIndex = 0;

          updatedWballs.forEach((ball) => {
            if (ball === "G") {
              finalBalls.push("G");
            } else {
              finalBalls.push(sortedBalls[sortedIndex]);
              sortedIndex++;
            }
          });

          return {
            ...card,
            wBall: finalBalls,
          };
        }
      })
    );
  };

  /** handleQuick */
  const handleQuick = (id: number) => {
    const generateUniqueNumbers = (
      count: number,
      range: number,
      exclude: number[]
    ) => {
      const uniqueNumbers = new Set<number>(exclude);
      while (uniqueNumbers.size < count + exclude.length) {
        const num = RandomNumber(range);
        if (!uniqueNumbers.has(num)) {
          uniqueNumbers.add(num);
        }
      }
      return Array.from(uniqueNumbers).slice(exclude.length);
    };

    setCard((prevCards) =>
      prevCards.map((card) => {
        if (card.id !== id) {
          return card; // 타겟 X 반환
        }

        const wBallSize =
          selectSystem === "PIC"
            ? 5
            : selectSystem === "ST6"
            ? 6
            : selectSystem === "ST7"
            ? 7
            : selectSystem === "ST8"
            ? 8
            : selectSystem === "ST9"
            ? 9
            : selectSystem === "STX"
            ? 10
            : 5; // 기본 5개 (STD, GUA)

        const wBallRange = BallSet[type].white;
        const sBallRange = BallSet[type].special;

        // wBall과 sBall 상태 확인
        const selectedWballs = card.wBall.filter(
          (ball) => ball !== 0 && ball !== "G"
        ) as number[];
        const isWballsFull = selectedWballs.length === wBallSize;
        const isWballsEmpty = selectedWballs.length === 0;
        const isSballSelected = card.sBall !== 0 && card.sBall !== "G";
        const isSballEmpty = card.sBall === 0 || card.sBall === "G";

        // GUA 처리
        if (selectSystem === "GUA") {
          if (isWballsFull || (isWballsEmpty && isSballEmpty)) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(
                wBallSize,
                wBallRange,
                []
              ).sort((a, b) => a - b);
              return {
                ...card,
                wBall: newWballs,
                sBall: "G",
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          } else {
            // 일부 빈 자리만 채우기
            const emptySlots = card.wBall.filter((ball) => ball === 0).length;
            const newNumbers = generateUniqueNumbers(
              emptySlots,
              wBallRange,
              selectedWballs
            );
            const finalWballs = card.wBall.map((ball) =>
              ball === 0 ? newNumbers.shift()! : ball
            );
            return {
              ...card,
              wBall: finalWballs.sort((a, b) => Number(a) - Number(b)),
              sBall: "G",
            };
          }
        }

        // PIC 처리
        if (selectSystem === "PIC") {
          if (
            card.wBall
              .filter((ball) => ball !== "G")
              .every((ball) => ball !== 0) &&
            card.sBall !== 0
          ) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(4, wBallRange, []).sort(
                (a, b) => a - b
              );
              const newSball = RandomNumber(sBallRange);

              return {
                ...card,
                wBall: [...newWballs, "G"],
                sBall: newSball,
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          }
          if (
            card.wBall.every((ball) => ball === 0 || ball === "G") &&
            card.sBall === 0
          ) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(4, wBallRange, []).sort(
                (a, b) => a - b
              );
              const newSball = RandomNumber(sBallRange);

              return {
                ...card,
                wBall: [...newWballs, "G"],
                sBall: newSball,
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          }

          const emptySlots = card.wBall.filter((ball) => ball === 0).length;
          if (emptySlots > 0 || card.sBall === 0) {
            const newNumbers = generateUniqueNumbers(
              emptySlots,
              wBallRange,
              selectedWballs
            );

            const finalWballs = card.wBall.map((ball, idx) =>
              ball === 0 && idx !== wBallSize - 1 ? newNumbers.shift()! : ball
            );

            const finalSball =
              card.sBall === 0 ? RandomNumber(sBallRange) : card.sBall;

            return {
              ...card,
              wBall: finalWballs.sort((a: any, b: any) =>
                a === "G" ? 1 : b === "G" ? -1 : a - b
              ),
              sBall: finalSball,
            };
          }

          return card;
        }

        // 기본 처리
        if (
          (isWballsFull && isSballSelected) ||
          (isWballsEmpty && isSballEmpty)
        ) {
          const autoPlay = () => {
            const requiredCount = wBallSize - (selectSystem === "PIC" ? 1 : 0);

            if (requiredCount <= 0) {
              return card;
            }

            const newWballs = generateUniqueNumbers(
              requiredCount,
              wBallRange,
              []
            ).sort((a, b) => a - b);

            if (newWballs.length < requiredCount) {
              return card;
            }

            const newSball =
              card.sBall === "G" ? "G" : RandomNumber(sBallRange);

            const finalWballs = card.wBall.map((ball, idx) =>
              ball === "G" || (selectSystem === "PIC" && idx === wBallSize - 1)
                ? "G"
                : newWballs.shift() ?? 0
            );

            return {
              ...card,
              wBall: finalWballs,
              sBall: newSball,
            };
          };

          const playQ = setInterval(() => {
            setCard((prevCards) =>
              prevCards.map((cardItem) => {
                if (cardItem.id === card.id) {
                  return autoPlay();
                }
                return cardItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(playQ);
          }, 1000);

          return card;
        }

        const emptySlots = card.wBall.filter((ball) => ball === 0).length;
        const newNumbers = generateUniqueNumbers(
          emptySlots,
          wBallRange,
          selectedWballs
        );
        const finalWballs = card.wBall
          .map((ball, idx) =>
            ball === 0 && !(selectSystem === "PIC" && idx === wBallSize - 1)
              ? newNumbers.shift()!
              : ball
          )
          .sort((a, b) => Number(a) - Number(b));

        const finalSball =
          (typeof card.sBall === "number" && card.sBall === 0) ||
          card.sBall === "G"
            ? card.sBall === "G"
              ? "G"
              : RandomNumber(sBallRange)
            : card.sBall;

        return {
          ...card,
          wBall: finalWballs,
          sBall: finalSball,
        };
      })
    );
  };

  useEffect(() => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const isType = type === "power" ? "pb" : "mm";

    axiosInstance
      .get(`/product/system/${isType}`)
      .then((res) => setSystem(res.data.data));
  }, [type]);

  /** Pick Random Nums */
  const generateUniqueNumbers = (count: number, range: number): number[] => {
    const uniqueNumbers = new Set<number>();
    while (uniqueNumbers.size < count) {
      uniqueNumbers.add(RandomNumber(range));
    }
    return Array.from(uniqueNumbers);
  };

  // Create Random Card of Systems
  useEffect(() => {
    const generateUniqueCard = (existingCards: GameCard[]) => {
      const uniqueCards: GameCard[] = [];

      while (uniqueCards.length < qty) {
        // 중복되지 wball 배열 생성
        let newWBall: any;
        do {
          newWBall = generateUniqueNumbers(
            selectSystem === "ST6"
              ? 6
              : selectSystem === "ST7"
              ? 7
              : selectSystem === "ST8"
              ? 8
              : selectSystem === "ST9"
              ? 9
              : selectSystem === "STX"
              ? 10
              : 5,
            BallSet[type].white
          ); // 각 시스템 갯수 설정
        } while (
          uniqueCards.some((card) => arraysAreEqual(card.wBall, newWBall))
        ); // 중복된 wBall시 재생성

        // selectSystem별 나머지 정보 설정
        let newCard;
        let idx = uniqueCards.length + 1;

        switch (selectSystem) {
          case "STD":
            newCard = {
              id: idx,
              wBall: newWBall.sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          case "GUA":
            newCard = {
              id: idx,
              wBall: generateUniqueNumbers(5, BallSet[type].white).sort(
                (a, b) => a - b
              ),
              sBall: "G",
              method: "A",
            };
            break;
          case "PIC":
            newCard = {
              id: idx,
              wBall: [
                ...generateUniqueNumbers(4, BallSet[type].white),
                "G",
              ].sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          case "ST6":
          case "ST7":
          case "ST8":
          case "ST9":
          case "STX":
            newCard = {
              id: idx,
              wBall: newWBall.sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          default:
            newCard = {
              id: idx,
              wBall: generateUniqueNumbers(5, BallSet[type].white).sort(
                (a, b) => a - b
              ),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
        }

        // 새로운 카드 미중복시 추가
        uniqueCards.push(newCard);
      }

      return uniqueCards;
    };

    const updatedCards = generateUniqueCard(card); // 기존 배열 넘겨 중복 방지
    setCard(updatedCards);
  }, [selectSystem, type, qty]);

  /** Equal Wball Array */
  const arraysAreEqual = (
    arr1: (number | string)[],
    arr2: (number | string)[]
  ) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  };

  useEffect(() => {
    if (multi) {
      if (
        Number((price / share).toFixed(1)) < 1 ||
        Number((price / share).toFixed(1)) === Infinity
      ) {
        setMinDollar(true);
      } else {
        setMinDollar(false);
      }
    } else {
      if (price / share < 1 || price / share === Infinity) {
        setMinDollar(true);
      } else {
        setMinDollar(false);
      }
    }
  }, [share, price]);

  const data = {
    type: type === "power" ? "pb" : "mm",
    system: selectSystem,
    quantity: qty,
    date: new Date(),
    multi: multi,
    share: share,
    title: gameText.title,
    explain: gameText.explain,
    price: price / share,
    writer: info.email,
  };

  console.log(data);

  return (
    // STEP1
    <div className="sub syndicate-create">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">Play with a group and get more chances to win</p>
      </div>

      <div className="create-wrap">
        <div className="stepper-wrap">
          <div
            className={`stepper ${
              step === 0 ? "active" : step > 0 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">01</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>

          <div
            className={`stepper ${
              step === 1 ? "active" : step > 1 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">02</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>

          <div
            className={`stepper ${
              step === 2 ? "active" : step > 2 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">03</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>
        </div>
        {step === 0 && (
          <>
            <div className="step step01">
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Select Game</h4>
                  <FormControl>
                    <Select
                      value={type}
                      onChange={(e) => handleChange(e, "type")}
                    >
                      <MenuItem value="power">USA Power Lottery</MenuItem>
                      <MenuItem value="mega">USA Mega Lottery</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select Lottery Systems</h4>
                  <FormControl fullWidth>
                    <Select
                      defaultValue={selectSystem}
                      onChange={(e) => handleChange(e, "system")}
                    >
                      <MenuItem value="STD">Standard</MenuItem>
                      {system.map((it, idx) => (
                        <MenuItem key={idx} value={it.code}>
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select Game Quantity</h4>
                  <div className="quantity-control">
                    <div
                      className="btn minus"
                      onClick={() => {
                        if (qty === 1) {
                          return;
                        } else {
                          setQty((prev) => prev - 1);
                        }
                      }}
                    >
                      -
                    </div>
                    <TextField
                      id=""
                      value={qty}
                      sx={{ width: "60px" }}
                      onChange={(e) => setQty(Number(e.target.value))}
                    />
                    <div
                      className="btn plus"
                      onClick={() => setQty((prev) => prev + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <h4 className="tit">Select Draw Date</h4>
                <FormControl sx={{ width: "50%" }}>
                  <Select value={1}>
                    <MenuItem value="1">Sat Nov 09 2024</MenuItem>
                    <MenuItem value="2">Tue Nov 12 2024</MenuItem>
                    <MenuItem value="3">Fri Nov 18 2024</MenuItem>
                    <MenuItem value="4">Sat Nov 09 2024</MenuItem>
                    <MenuItem value="5">Sat Nov 09 2024</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="item">
                <h4 className="tit">Multiplier</h4>
                <div className="layout">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={multi}
                        onClick={() => setMulti((prev) => !prev)}
                      />
                    }
                    label="Multiplier added"
                  />
                  <div className="txt-wrap">
                    <p className="txt">$ 3.45 per combination</p>
                    <p className="exp">
                      Boost the potential winnings up to 5 times on all
                      non-jackpot prizes!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="step step02 shortfall">
              <h4 className="tit">Total</h4>
              <p>
                <span className="num">$ {price}</span>
                {checkAccount()}
              </p>
            </div>
          </>
        )}

        {/* STEP2 */}
        {step === 1 && (
          <div className="step step03">
            <div className="item">
              <h4 className="tit">The Number of Share</h4>

              <div className="layout">
                <TextField
                  value={share}
                  disabled={setting1}
                  sx={{ width: "120px" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      // only Number
                      setShare(Number(value));
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
                <p className="cost">
                  Cost per share :
                  <span>
                    $
                    {!setting1
                      ? (price / share).toFixed(2)
                      : (price / make1dollar(price)).toFixed(2)}
                  </span>
                </p>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={setting1}
                      onClick={() => {
                        setSetting1((prev) => !prev);
                        setShare(make1dollar(price));
                      }}
                    />
                  }
                  label="Create a $ 1"
                />
                {/* <div className="btn style01 sm">$ 1</div> */}
              </div>

              {minDollar ? (
                <div className="redTxt">
                  A minimum of 1 dollar must be maintained.
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="item">
              <h4 className="tit">Syndicate Title</h4>
              <TextField
                id="title"
                onChange={(e) => handleTextChange("title", e.target.value)}
                value={gameText.title}
                placeholder="Enter the title to display in the list"
                sx={{ width: "50%" }}
              />

              <h4 className="tit" style={{ marginTop: "24px" }}>
                Syndicate Explain
              </h4>
              <TextField
                id="explain"
                onChange={(e) => handleTextChange("explain", e.target.value)}
                value={gameText.explain}
                placeholder="Enter the details to join your game"
                fullWidth
                multiline
                rows={4}
              />
            </div>
          </div>
        )}

        {/* STEP3 */}
        {step === 2 && (
          <div className="step step04">
            <div className="ticket-wrap">
              {card.map((it, idx) => (
                <CreateCard
                  key={idx}
                  data={it}
                  idx={idx}
                  type={type}
                  handleClear={handleClear}
                  handlePick={handlePick}
                  handleQuick={handleQuick}
                />
              ))}
            </div>
          </div>
        )}

        {/* STEP4 */}
        {step === 3 && (
          <div className="step step05">
            <div className="info-wrap">
              <h3>Syndicate Summary</h3>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Game</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue={`${
                        type === "power"
                          ? "USA Power Lottery"
                          : "USA Mega Lottery"
                      }`}
                    />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Systems</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue={SystemFinder(selectSystem)}
                    />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Total Count</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue={qty}
                      sx={{ width: "60px" }}
                    />
                  </p>
                </div>
              </div>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Draw Date</h4>
                  <p className="txt">
                    <TextField id="" disabled defaultValue="Sat Nov 09 2024" />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Multiplier</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue={`${multi ? "O" : "X"}`}
                      sx={{ width: "60px" }}
                    />
                  </p>
                </div>
              </div>
              <div className="item">
                <h4 className="tit">Title</h4>
                <p className="txt">
                  <TextField
                    id=""
                    disabled
                    defaultValue={gameText.title}
                    sx={{ width: "50%" }}
                  />
                </p>

                <h4 className="tit" style={{ marginTop: "24px" }}>
                  Explain
                </h4>
                <p className="txt">
                  <TextField
                    id=""
                    fullWidth
                    multiline
                    rows={4}
                    disabled
                    defaultValue={gameText.explain}
                  />
                </p>
              </div>

              <div className="item">
                <h4 className="tit">Selected Ball Numbers</h4>
                <div className="show-number-wrap">
                  {card.map((it, idx) => {
                    return (
                      <div key={idx} className="ticket-wrap">
                        <div className="item">
                          <div className="ticket-tit-wrap">
                            <p>Game # {idx + 1}</p>
                          </div>
                          <div className="picked-ball-wrap">
                            <div className="ball-wrap">
                              {it.wBall.map((it, idx) => (
                                <div key={idx} className="ball">
                                  {it}
                                </div>
                              ))}
                              <div className={`ball ${type}`}>{it.sBall}</div>
                            </div>
                          </div>
                          <ShowNums
                            key={idx}
                            nums={String([...it.wBall, it.sBall])}
                            code={type === "power" ? "PB" : "MM"}
                            sysCode={selectSystem}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="agree-wrap">
              <FormControlLabel
                onChange={() => setAgree((prev) => !prev)}
                checked={agree}
                control={<Checkbox />}
                label={
                  <>
                    Once a syndicate is created, it cannot be easily deleted or
                    modified.
                    <br />A user can only create one syndicate.
                  </>
                }
              />
            </div>
          </div>
        )}

        <div className="btn-wrap prev-next">
          {step !== 0 && (
            <div
              className="btn style01 md prev"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 3 ? (
            <div
              className={`btn style01 md spc next ${isDisabledStep()}`}
              onClick={() => nextStepHandler()}
            >
              Next Step
            </div>
          ) : (
            <div className={`btn style01 md spc ${!agree && "disabled"}`}>
              Create Syndicate
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyndicateCreate;
