export const make1dollar = (price: number) => {
  const targetCost = 1;

  // 올림, 내림, 반올림
  const shareRound = Math.round(price / targetCost);
  const shareCeil = Math.ceil(price / targetCost);
  const shareFloor = Math.floor(price / targetCost);

  // 값 비교
  const costRound = price / shareRound;
  const costCeil = price / shareCeil;
  const costFloor = price / shareFloor;

  // $1에 가장 가까운 값 찾기
  if (
    Math.abs(costRound - targetCost) <= Math.abs(costCeil - targetCost) &&
    Math.abs(costRound - targetCost) <= Math.abs(costFloor - targetCost)
  ) {
    return shareRound;
  } else if (
    Math.abs(costCeil - targetCost) <= Math.abs(costFloor - targetCost)
  ) {
    return shareCeil;
  } else {
    return shareFloor;
  }
};
