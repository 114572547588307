import react, { useState } from "react";
import { ResultData } from "../../pages/result2";
import amountMaker from "../../tools/amountMaker";
import Division from "../../constants/division";

// ui
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Switch from "@mui/material/Switch";

// img
import StarsMega from "../../img/sub/stars-mega.json";
import Mega from "../../img/common/mega-ball-logo.png";
import Power from "../../img/common/power-ball-logo.png";
import MegaLogo from "../../img/common/mega-white-logo.svg";
import PowerLogo from "../../img/common/power-white-logo.svg";
import React from "react";
import { CustModal } from "../common/commonElements";

// types
interface ResultCardProps {
  type: string;
  data: ResultData;
}

const ResultCard = ({ type, data }: ResultCardProps) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMulti, setIsMulti] = useState<boolean>(true);

  const prize = data.prizes.split(",").map(Number);
  const balls = data.winningNumber.split(",");
  const date = data.auDrawTime.split(" ")[0];

  const reward = amountMaker(prize[0] * Number(data.exchange));

  return (
    <div className="item">
      <div
        className="game-info-area"
        onClick={() => {
          setDetailOpen(!detailOpen);
        }}
      >
        {/* <img
            src={type === "power" ? Power : Mega}
            alt={type === "power" ? "Powerball" : "Mega millions"}
          /> */}
        <div className="item-section">
          <div className="tag-wrap">
            <p className={`name ${type === "power" ? "power" : "mega"}`}>
              {type === "power" ? "USA Power Lottery" : "USA Mega Lottery"}
            </p>
            <p className="tag multiplier">Multiplier {data.multiplier}x</p>
          </div>
          <p className="prize">
            <span className="unit">{reward.mark} </span>
            <span className="num">{reward.amount}</span>
            <span className="unit">{reward.unit}</span>
          </p>
          <p className="time">{new Date(date).toDateString()}</p>
        </div>
        <div className="item-section">
          <h5 className="tit">Winning Numbers</h5>
          <div className="ball-wrap">
            {balls.map((it, idx) => (
              <div
                key={idx}
                className={`ball ${
                  idx === balls.length - 1
                    ? type === "power"
                      ? "power"
                      : "mega"
                    : ""
                }`}
              >
                {it}
              </div>
            ))}
          </div>
        </div>
        <div className="item-section">
          <div
            className="btn style01 md video"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <span className="material-symbols-rounded">videocam</span>
            Video
          </div>

          {/* 영상 모달창 */}
          <CustModal
            open={open}
            setOpen={setOpen}
            onClose={(e: any) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            <div className="video">
              <div className="modal-tit-wrap">
                <h3 className="tit">Video</h3>
                <span
                  className="btn close material-symbols-rounded"
                  onClick={() => setOpen(false)}
                >
                  close
                </span>
              </div>
              <div className="modal-cont-wrap">
                <iframe
                  src={`https://www.youtube-nocookie.com/embed/${data.videoId}?autoplay=1&rel=0`}
                  width="100%"
                  height="100%"
                  allowFullScreen
                  allow="autoplay"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </CustModal>

          <div className={`btn style02 ${detailOpen && "open"}`}>
            {detailOpen ? "View Less" : "View More"}
            <span className="material-symbols-rounded">
              keyboard_arrow_down
            </span>
          </div>
        </div>
      </div>

      <div className={`vertical-trans ${detailOpen ? "open" : "close"}`}>
        <div className="show-detail">
          <div className="btn style01 sm">View Match</div>
          <FormControlLabel
            sx={{ display: "flex", margin: "0 0 12px auto" }}
            control={
              <Switch
                checked={isMulti}
                onClick={() => setIsMulti((prev) => !prev)}
              />
            }
            label={
              <p className={`multi ${isMulti ? "" : "non"}`}>
                {isMulti ? `Multiplier ${data.multiplier}X` : "Default Lottery"}
              </p>
            }
            labelPlacement="start"
          />

          <div className="table-wrap">
            <table>
              <colgroup>
                <col width="15%" />
                <col width="" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Tier</th>
                  {/* <th>Winning Combination</th> */}
                  <th>Prize</th>
                  <th>Winners</th>
                </tr>
              </thead>
              <tbody>
                {Division.map((it, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {/* <td>
                      <div className="ball-wrap">
                        {Array.from({ length: it.white }).map((_, idx) => (
                          <div key={idx} className="ball" />
                        ))}
                        {it.special && (
                          <div
                            className={`ball ${
                              type === "power" ? "power" : "mega"
                            }`}
                          />
                        )}
                      </div>
                    </td> */}
                    <td>
                      {isMulti
                        ? idx === 0
                          ? `$ ${(
                              Number(data.prizes.split(",")[0]) *
                              Number(data.exchange)
                            ).toLocaleString()}`
                          : `$ ${(
                              Number(data.multiPrizes.split(",")[idx]) *
                              Number(data.exchange)
                            ).toLocaleString()}`
                        : `$ ${(
                            Number(data.prizes.split(",")[idx]) *
                            Number(data.exchange)
                          ).toLocaleString()}`}
                    </td>

                    <td>
                      {isMulti
                        ? data.multiWinners.split(",")[idx]
                        : data.winners.split(",")[idx]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
