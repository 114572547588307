// img
// import EventImg01 from "../../img/main/event-img01.png";
import EventImg02 from "../../img/main/test.png";

const Event = () => {
  return (
    <section className="event-area">
      <div className="txt-wrap">
        <h3>$ 1 In Your Pocket?</h3>
        <h2>Play Now!</h2>
      </div>
      <img
        src={EventImg02}
        style={{ width: "320px" }}
        alt="Could you be the next winner?"
      />
    </section>
  );
};

export default Event;
