import { useLotto } from "../../App";
import amountMaker from "../../tools/amountMaker";

//img
import MegaLogo from "../../img/common/USA_Mega_lottery.png";
import PowerLogo from "../../img/common/USA_Power_lottery.png";
// import MegaLogo from "../../img/common/mega-white-logo.svg";
// import PowerLogo from "../../img/common/power-white-logo.svg";
import CountDown from "./countdown";

interface FloatingGameProps {
  type: string;
}

const FloatingGame = ({ type }: FloatingGameProps) => {
  const { lottery } = useLotto();

  const isResult = type?.length < 1 ? false : true;

  const isType = (type: string) => {
    const lotteryType = type === "mega" ? "MM" : "PB";
    return lottery.find((lot) => lot.type === lotteryType);
  };

  const bigType = lottery.reduce(
    (max, item) => (item.jackpot > max.jackpot ? item : max),
    lottery[0] || {}
  );
  const navigater = (type: string) => {
    if (type === "mega" || type === "MM") {
      location.href = "/mega";
    } else if (type === "power" || type === "PB") {
      location.href = "/power";
    }
  };

  const reward = amountMaker(
    Number(isType(type)?.jackpot) * Number(isType(type)?.exchange)
  );

  const reward2 = amountMaker(
    Number(bigType?.jackpot) * Number(bigType?.exchange)
  );

  return isResult ? (
    <div className={`floating-banner ${type}`}>
      <div className="iconArea">
        <img
          src={type === "power" ? PowerLogo : MegaLogo}
          alt={`${type} logo`}
        />
      </div>
      <div className="gameInfo">
        {isType(type)?.isDrawdate === 0 ? (
          <p className="prize">
            <span className="unit"> {reward.mark} </span>
            <b style={{ margin: "0px 4px" }}>{reward.amount}</b>
            <span className="unit"> {reward.unit} </span>
          </p>
        ) : (
          <p className="prize">Pending</p>
        )}
        <p className="close">
          <CountDown
            date={String(isType(type)?.auOrderTime)}
            pending={Number(isType(type)?.isDrawdate)}
          />
        </p>
      </div>
      <div className="btn style01 sm white" onClick={() => navigater(type)}>
        PLAY <br />
        NOW
      </div>
    </div>
  ) : (
    <div className={`floating-banner ${type}`}>
      <img
        src={bigType.type === "PB" ? PowerLogo : MegaLogo}
        alt={`${type} logo`}
      />

      <div className="gameInfo">
        <p className="prize">
          <span className="unit"> {reward.mark} </span>
          <b>{reward2.amount}</b>
          <span className="unit"> {reward.unit}</span>
        </p>
        <p className="close">
          <CountDown
            date={String(bigType?.auOrderTime)}
            pending={Number(bigType?.isDrawdate)}
          />
        </p>
      </div>
      <div
        className="btn style01 sm white"
        onClick={() => navigater(bigType?.type)}
      >
        PLAY <br />
        NOW
      </div>
    </div>
  );
};

export default FloatingGame;
